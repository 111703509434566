import React from 'react'
import Helmet from 'react-helmet'

import Layout from '../components/layout'
import KLink from '../components/Core/KLink'
import Text from '../components/Core/Text'
import MainTitle from '../components/Shared/MainTitle'
import * as styles from './styles/error.module.scss'
import { useTranslation } from '../hooks/useTranslation'
import illus from '../images/svg/phare-02.svg'

export default function NotFound() {
  const { t } = useTranslation('common')

  return (
    <>
      <Helmet bodyAttributes={{ class: 'error-page light-header' }} />
      <Layout>
        <div className="container">
          <div className={styles.mainError}>
            <div className={styles.errorWrap}>
              <div className={styles.errorMsg}>
                <MainTitle title={t('Not found title', 'common')} />
                <Text tag="p" as="subtitleH1">
                  {t('Not found content', 'common')}
                </Text>
                <KLink
                  to="/"
                  label={`${t('Not found link', 'common')}`}
                  btnType="dark"
                />
              </div>
              <div className={styles.notFoundIllus}>
                <img src={illus} alt="" />
              </div>
            </div>
          </div>
        </div>
      </Layout>
    </>
  )
}
