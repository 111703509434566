import React from 'react'
import Text from '../../Core/Text'
import * as styles from './mainTitle.module.scss'

interface MainTitleProps {
  title: string
  tag?: keyof JSX.IntrinsicElements
}

export default function MainTitle(props: MainTitleProps) {
  const { title, tag = 'h1' } = props
  return (
    <Text
      tag={tag}
      as="h1"
      className={`${styles.title} h1 bg-vert-fonce text-typo-title`}
      dangerouslySetInnerHTML={{ __html: title }}
    ></Text>
  )
}
